@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  html {
    font-family:
      'Poppins',
      system-ui sans-serif;
  }
}

body {
  margin: 0;
  font-family:
    'Poppins',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

#google_translate_element {
  background: #fff;
}

.skiptranslate.goog-te-gadget {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.skiptranslate.goog-te-gadget span a {
  display: flex;
  align-items: center;
  margin: 3px;
}

.step::before {
  content: none !important;
}

.progress-bar {
  width: 100%;
  animation: progress-animation 900s linear forwards;
}

@keyframes progress-animation {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
